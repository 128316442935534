<template>
  <div>
    <b-card>
      <AgGrid
        ref="OneStepupArchiveGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      />
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/Miscellaneous/OneStepUpContainerArchiveBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  components: {
    AgGrid,
  },
  data() {
    return {
      required,
      TempLineId: '',
      aggrid: [],
      ContainerTypeOption: [],
      container: {
        Id: '',
        Container: '',
        SailDate: '',
        ETA: '',
        Vessel: '',
        MBL: '',
        LoadPort: '',
        DischargePort: '',
        ATD: '',
        ATA: '',
      },
      Role: '',
      Permission: [],

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = 'c3FsMDEuc21hcnRlY2hjLmNvbSwxNDg4O0FwZXhPbmVTdGVwVXBTeXN0ZW07YXBleGFiaTtBcGV4QUJJIQ=='
    // Get user role
    this.Role = this.GetUserRole()

    this.aggrid = this.InitGridInstance('OneStepUpContainersArchive')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'id',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      { headerName: 'Container', field: 'container', minWidth: 100 },
      { headerName: 'MBL', field: 'mbl', minWidth: 100 },
      {
        headerName: 'ETD',
        field: 'sailDate',
        minWidth: 125,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'ETA',
        field: 'eta',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'ATD',
        field: 'atd',
        minWidth: 125,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'ATA',
        field: 'ata',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      { headerName: 'Vessel', field: 'vessel', minWidth: 100 },
      { headerName: 'Load Port', field: 'loadPort', minWidth: 100 },
      { headerName: 'Discharge Port', field: 'dischargePort', minWidth: 100 },
      {
        headerName: 'Created On',
        field: 'createdOn',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      { headerName: 'Last Updated', field: 'updatedBy', minWidth: 100 },
      {
        headerName: 'Updated On',
        field: 'updatedOn',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.OneStepupArchiveGrid.saveState('OneStepUpContainersArchive') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.OneStepupArchiveGrid.saveState('OneStepUpContainersArchive') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.OneStepupArchiveGrid.saveState('OneStepUpContainersArchive') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.OneStepupArchiveGrid.saveState('OneStepUpContainersArchive') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(containerId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, unarchive it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/misc/onestepup/containers/${containerId}/unarchive`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Container has been unarchived.')
              .then(() => {
                // redirect
                this.LoadContainers()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Container cannot be unarchived.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadContainers()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadContainers() {
      // Get permission
      this.Permission = this.GetUserPermission('Misc')
      return axios.get('/misc/onestepup/containers/archive')
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.OneStepupArchiveGrid.ResetColumns()
        })
    },
    Delete(containerId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/misc/onestepup/containers/archive/${containerId}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Container has been removed.')
              .then(() => {
                // redirect
                this.LoadContainers()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Container cannot be removed.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
