import Vue from 'vue'
import axios from '@axios'

export default Vue.extend({
  data() {
    return {
      Role: '',
      Permission: [],
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = 'c3FsMDEuc21hcnRlY2hjLmNvbSwxNDg4O0FwZXhPbmVTdGVwVXBTeXN0ZW07YXBleGFiaTtBcGV4QUJJIQ=='
    this.UserPermission()
  },
  mounted() { },
  methods: {
    UserPermission() {
      // Get user role
      this.Role = this.GetUserRole()
      this.Permission = this.GetUserPermission('Misc')
    },
    Clickhandler() {
      let returnID = ''
      if (this.params.data.id) returnID = this.params.data.id

      this.params.context.componentParent.Open(returnID)
    },
    Deletehandler() {
      let returnID = ''
      if (this.params.data.id) returnID = this.params.data.id

      this.params.context.componentParent.Delete(returnID)
    },
  },
  template: `
        <span>
            <b-button
            v-b-tooltip.hover="'Unarchive'"
            size="sm" 
            v-if="Role == 'company admin'"
            @click="Clickhandler()" 
            variant="primary" 
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon"
            >
            <font-awesome-icon
              :icon="['fas', 'folder-open']"
              size="lg"
            />
            </b-button>
            <b-button
            v-if="Role == 'company admin'"
            v-b-tooltip.hover="'Delete'"
            size="sm" 
            @click="Deletehandler()" 
            variant="danger" 
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon"
            >
            <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              size="lg"
            />
            </b-button>
        </span>
    `,
})
